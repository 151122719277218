module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"celimage","accessToken":"MC5YNkJNMkJFQUFCOEEwTDNo.Se-_vVHvv70OGe-_vRE477-977-9de-_ve-_vRhQ77-977-977-977-9dSIFVlTvv70zIe-_vQ3vv70m","schemas":{"projects":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1","label":"title"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"content"}},"images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image"}}},"label":"images"}},"uid":{"type":"UID","config":{"label":"slug"}}}},"site_config":{"Main":{"blog_title":{"type":"Text","config":{"label":"Blog title"}},"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"logo"}},"place":{"type":"Text","config":{"label":"place"}},"instagram":{"type":"Link","config":{"allowTargetBlank":true,"label":"Instagram"}},"about_me":{"type":"StructuredText","config":{"multi":"paragraph","label":"About me"}}}}},"prismicToolbar":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Celimage","short_name":"Celimage","description":"Blog photo par Celine Kerleroux","start_url":"/","background_color":"#fff","theme_color":"#3182ce","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
